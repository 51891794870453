import {findIndex, find} from 'lodash';
import styles from '../../../components/contentManagement/Content.module.scss';
export const fetchImageList = async (props) => {
  const {
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
  } = props;
  const {filtersList} = filterData;
  const checkBoxData = find(filtersList, ['filterType', 'CHECKBOX']);
  const {filterTypeData} = checkBoxData;
  const {sectionData} = filterTypeData;
  const checkBoxInUseData = find(sectionData, ['label', 'In-Use']);
  const checkBoxNotInUseData = find(sectionData, ['label', 'Not In-Use']);
  const [sortType, apiParamUsedInCourse, apiParamNotUsedInCourse] = setCategory(
    checkBoxInUseData,
    checkBoxNotInUseData,
    filtersList,
  );

  const radioButtonsData = find(filtersList, ['filterType', 'RADIO']);
  //let selectedRadioButtonData = {};
  radioButtonFilterData({radioButtonsData});

  const _endpoint = searchEndpoint({
    search,
    apiParamUsedInCourse,
    apiParamNotUsedInCourse,
    sortType,
  });
  let dataSearchAndSort = await makeRequest({
    method: 'GET',
    endpoint: _endpoint,
  });
  searchAndSort(
    dataSearchAndSort,
    setImageList,
    setImageListAll,
    // callbackFunction,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
  );
  setFilterDropdown(false);
};

export const fetchImageDetails = async (props) => {
  const {
    imageId,
    makeRequest,
    isImageDetailDrawerUpdated,
    setNotificationData,
    setImageDetailsData,
    imageinput,
    setImageDetailDrawerUpdated,
    setImageDetail,
    setImageInput,
    setDescriptionInput,
    setCourseIds,
    setMainImageBackup,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setCourseDetails,
  } = props;
  const _endpoint = `/admin/api/v1/images/${imageId}`;
  let dataImageDetail = await makeRequest({
    method: 'GET',
    endpoint: _endpoint,
  });
  if (dataImageDetail != 'ERR_NETWORK' && dataImageDetail) {
    setImageDetailsData(
      dataImageDetail,
      isImageDetailDrawerUpdated,
      setNotificationData,
      imageinput,
      setImageDetailDrawerUpdated,
      setImageDetail,
      setImageInput,
      setDescriptionInput,
      setCourseIds,
      setMainImageBackup,
    );
    setDrawerAPIFailed(false);
  } else if (dataImageDetail == 'ERR_NETWORK') {
    if (imageId != undefined && imageId !== drawerIdForBrokenLink) {
      setDrawerIdForBrokenLink(imageId);
    }
    setDrawerAPIFailed(true);
  }
  setCourseDetails(dataImageDetail?.data?.instances);
};

export const fetchKeywords = async (props) => {
  const {makeRequest, setKeywordSuggestion, setKeywordSuggestionCopy} = props;
  const _endpoint = '/api/v1/keywords';
  let dataFetchKeywords = await makeRequest({
    method: 'GET',
    endpoint: _endpoint,
  });
  setSuggestion({dataFetchKeywords, setKeywordSuggestion, setKeywordSuggestionCopy});
  setSuggestionData({dataFetchKeywords, setKeywordSuggestion, setKeywordSuggestionCopy});
};

export const searchEndpoint = (props) => {
  const {search, apiParamUsedInCourse, apiParamNotUsedInCourse, sortType} = props;

  return `/admin/api/v1/images?searchString=${search && search}&usedInCourse=${
    apiParamUsedInCourse ? 'yes' : '' || apiParamNotUsedInCourse ? '' : 'no'
  }&sortBy=${sortType}`;
};

export const setSuggestion = (props) => {
  const {dataFetchKeywords, setKeywordSuggestion, setKeywordSuggestionCopy} = props;
  if (dataFetchKeywords) {
    setKeywordSuggestion(dataFetchKeywords?.data);
    setKeywordSuggestionCopy(dataFetchKeywords?.data);
  }
};

export const deleteImageMessage = (props) => {
  const {
    dataDeleteImageAsset,
    setNotificationData,
    setDrawer,
    setImageId,
    setMainImg,
    fetchImageList,
    imageinput,
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
  } = props;
  if (dataDeleteImageAsset) {
    setNotificationData({
      show: true,
      type: 'SUCCESS',
      title: 'Image Deleted',
      description: `${imageinput} has been deleted.`,
    });
    setDrawer(false);
    setImageId(0);
    setMainImg(undefined);
    fetchImageList({
      filterData,
      search,
      makeRequest,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
      isOnline,
      setImageList,
      setImageListAll,
      searchAndSort,
      setFilterDropdown,
      setCategory,
    });
  }
};

export const searchValidation = (props) => {
  const {event, setNotificationData} = props;
  /// Bug raised by QA for search input changes 27-02-2023 commenting below search code
  // if (event.charCode == 13 && event?.target?.value.length <= 2) {
  //   setNotificationData({
  //     show: true,
  //     type: 'WARNING',
  //     title: 'Search requires 3 or more characters',
  //     description: 'Please enter a longer search term.',
  //   });
  // }
};

export const handleImageName = (props) => {
  const {value, ImageDetail, usedFileNameList, setImageDetailDrawerUpdated, updateImageDetails} =
    props;
  if (
    value.trim() !== '' &&
    value?.length > 2 &&
    ImageDetail &&
    ImageDetail?.fileName !== value &&
    (usedFileNameList?.length < 0 || usedFileNameList?.length == [])
  ) {
    setImageDetailDrawerUpdated(true);
    updateImageDetails({['fileName']: value.trim(), ['tags']: ImageDetail?.tags.join(',')});
  }
};

export const getUsedFileName = (props) => {
  const {value, ImageList, ImageDetail} = props;
  return ImageList.filter((item) => {
    if (
      item.fileName.toLowerCase() == value.toLowerCase() &&
      ImageDetail?.imageId !== item?.imageId
    ) {
      return item;
    }
  });
};

export const handleDescriptionChange = (props) => {
  const {value, setDescriptionInput} = props;
  if (value.length <= 500) {
    setDescriptionInput(value);
  }
};

export const keywordInputHandle = (props) => {
  const {
    value,
    setUpdatedKeywordSuggestionCopy,
    keywordSuggestion,
    setKeywordDropdown,
    setKeywordSuggestion,
    KeywordSuggestionCopy,
  } = props;
  if (value.length > 0) {
    setUpdatedKeywordSuggestionCopy(
      keywordSuggestion?.filter((itemss) =>
        itemss?.title?.toLowerCase().match(new RegExp(value?.toLowerCase())),
      ),
    );
    setKeywordDropdown(true);
  } else {
    setKeywordDropdown(false);
    setKeywordSuggestion(KeywordSuggestionCopy);
  }
};

export const closeSortAndFilter = (props) => {
  const {e, sortAndFilterFlyout, filterDropdown, setFilterDropdown} = props;
  if (
    sortAndFilterFlyout.current &&
    !filterDropdown &&
    !sortAndFilterFlyout.current.contains(e.target) &&
    e.target.className &&
    e.target.className.indexOf('Content_') !== -1
  ) {
    setFilterDropdown(false);
  }
};

export const handleDescription = (props) => {
  const {value, descriptionInput, ImageDetail, setImageDetailDrawerUpdated, updateImageDetails} =
    props;
  if (descriptionInput.length <= 500 && ImageDetail.description !== value) {
    setImageDetailDrawerUpdated(true);
    updateImageDetails({['description']: descriptionInput, ['tags']: ImageDetail?.tags.join(',')});
  }
};

export const handleCloseImageLib = (props) => {
  const {newCourseWizardState, showImageLibrary, setCloseImageLib} = props;
  if (newCourseWizardState && newCourseWizardState?.courseWizardOpen && !showImageLibrary) {
    setCloseImageLib();
  }
};

export const drawerOutsideClick = (props) => {
  const {
    event,
    closeDrawerModal,
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setDrawer,
    setMainImg,
    setImageId,
  } = props;

  if (
    event.target.className.includes('Content_custom_overlay') ||
    event.target.className.includes('Content_mob_bar') ||
    event.target.className.includes('Content_line_bar')
  ) {
    closeDrawerModal({
      filterData,
      search,
      makeRequest,
      setSearchFilterEmptyState,
      setErrorStateTemplate,
      isOnline,
      setImageList,
      setImageListAll,
      searchAndSort,
      setFilterDropdown,
      setCategory,
      setDrawer,
      setMainImg,
      setImageId,
    });
  }
};

export const radioButtonFilterData = (props) => {
  const {radioButtonsData} = props;
  radioButtonsData &&
    radioButtonsData.filterTypeData &&
    radioButtonsData.filterTypeData.sectionData &&
    radioButtonsData.filterTypeData.sectionData.length > 0 &&
    radioButtonsData.filterTypeData.sectionData.map((radioButton) => {
      if (radioButton && radioButton.checked) {
        //selectedRadioButtonData = radioButton;
      }
    });
};

export const handleToggleSwitch = async (props) => {
  const {value, setIsCardView, setIsListView} = props;
  if (value === 'listView') {
    setIsCardView(false);
    setIsListView(true);
  } else {
    setIsCardView(true);
    setIsListView(false);
  }
};

export const closeImageLib = (props) => {
  const {
    newImageID,
    showImageLibrary,
    setCloseImageLib,
    setNewThumbnailId,
    courseMode,
    learningPathMode,
  } = props;
  newImageID && !showImageLibrary && setCloseImageLib();
  (courseMode === 'FROM_COURSE_WIZARD' || learningPathMode === 'FROM_LEARNING_PATH') &&
    newImageID &&
    setNewThumbnailId(newImageID);
};

export const setSuggestionData = (props) => {
  const {dataFetchKeywords, setKeywordSuggestion, setKeywordSuggestionCopy} = props;
  if (dataFetchKeywords) {
    setKeywordSuggestion(dataFetchKeywords?.data);
    setKeywordSuggestionCopy(dataFetchKeywords?.data);
  }
};

export const getMainImage = (props) => {
  const {imgId, croppedImageData, mainImg} = props;

  return imgId?.length > 0 && croppedImageData?.croppedImage?.croppedImageData?.img
    ? croppedImageData.croppedImage.croppedImageData.img
    : mainImg;
};

export const get_16_9_image = (props) => {
  const {imgId, croppedImageData, mainImageBackup, ratio} = props;

  return imgId?.length > 0 &&
    croppedImageData?.croppedImage?.croppedImageData?.img &&
    ratio === '16:9'
    ? croppedImageData.croppedImage.croppedImageData.img
    : mainImageBackup;
};

export const setImageDrawer = (props) => {
  const {
    imageId,
    setDrawer,
    fetchKeywords,
    fetchImageDetails,
    learningPathMode,
    makeRequest,
    isImageDetailDrawerUpdated,
    setNotificationData,
    setImageDetailsData,
    imageinput,
    setImageDetailDrawerUpdated,
    setImageDetail,
    setImageInput,
    setDescriptionInput,
    setCourseIds,
    setMainImageBackup,
    setDrawerAPIFailed,
    drawerIdForBrokenLink,
    setDrawerIdForBrokenLink,
    setCourseDetails,
    setKeywordSuggestion,
    setKeywordSuggestionCopy,
  } = props;
  if (imageId !== 0 && learningPathMode !== 'FROM_LEARNING_PATH') {
    setDrawer(true);
    fetchKeywords({
      makeRequest,
      setKeywordSuggestion,
      setKeywordSuggestionCopy,
    });
    fetchImageDetails({
      imageId,
      makeRequest,
      isImageDetailDrawerUpdated,
      setNotificationData,
      setImageDetailsData,
      imageinput,
      setImageDetailDrawerUpdated,
      setImageDetail,
      setImageInput,
      setDescriptionInput,
      setCourseIds,
      setMainImageBackup,
      setDrawerAPIFailed,
      drawerIdForBrokenLink,
      setDrawerIdForBrokenLink,
      setCourseDetails,
    });
  }
};

export const handleFilterApply = async (props) => {
  const {
    params,
    setFilter,
    filterData,
    findIndex,
    fetchImageList,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setIsLoading,
  } = props;
  setIsLoading(true);
  setFilter(filterData, params, findIndex);
  await fetchImageList({
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
  });
  setTimeout(() => {
    setIsLoading(false);
  }, 500);
};

export const mobileDrawerOutsideClick = (props) => {
  const {
    event,
    closeDrawerModal,
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setDrawer,
    setMainImg,
    setImageId,
  } = props;

  drawerOutsideClick({
    event,
    closeDrawerModal,
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setDrawer,
    setMainImg,
    setImageId,
  });
};

export const handleDeleteImageAsset = async (props) => {
  const {
    imageId,
    makeRequest,
    setNotificationData,
    setDrawer,
    setImageId,
    setMainImg,
    imageinput,
    filterData,
    search,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    currentUserId,
  } = props;
  const _endpoint = `/admin/api/v1/images/${imageId}`;
  let dataDeleteImageAsset = await makeRequest({
    method: 'DELETE',
    endpoint: _endpoint,
  });
  deleteImageMessage({
    dataDeleteImageAsset,
    setNotificationData,
    setDrawer,
    setImageId,
    setMainImg,
    fetchImageList,
    imageinput,
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
  });

  setDashboardNotificationCountApi(currentUserId);
  setNotificationListApi(currentUserId);
};

export const handleOnClickReplaceImageOnAllInstances = (props) => {
  const {
    setImageData,
    setReplacedImage,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setShowReplaceModal,
    setDeletePreviousImage,
  } = props;
  setImageData(null);
  setReplacedImage(null);
  setCroppedImage_16_9(null);
  setCroppedImage_1_1(null);
  setShowReplaceModal(true);
  setDeletePreviousImage(false);
};

export const closeSortAndFilterFlyout = (props) => {
  const {e, sortAndFilterFlyout, filterDropdown, setFilterDropdown} = props;

  closeSortAndFilter({e, sortAndFilterFlyout, filterDropdown, setFilterDropdown});
};

export const handleImageNameChange = (props) => {
  const {
    value,
    ImageList,
    ImageDetail,
    setFileError,
    setImageInput,
    updateImageName,
    setUsedFileName,
  } = props;

  let usedFileName = getUsedFileName({value, ImageList, ImageDetail});
  setUsedFileName(usedFileName);
  updateImageName(ImageDetail, value, setFileError, setImageInput, usedFileName);
};

export const handleSearchInput = (props) => {
  const {
    event,
    modifyValue,
    setSearch,
    setPredictedSearch,
    setNotificationData,
    ImageList,
    setTempPredition,
  } = props;
  setSearch(modifyValue(event?.target?.value));
  setPredictedSearch([]);
  searchValidation({event, setNotificationData});
  setTempPredition(ImageList, setPredictedSearch);
};

export const handleSelectedKeyword = (props) => {
  const {item, setSelectedKeyword, setKeywordDropdown} = props;

  setSelectedKeyword(item);
  setKeywordDropdown(false);
};

export const cropButton = (props) => {
  const {
    setButton_169_status,
    setButton_11_status,
    setCroppedImage_16_9,
    setCroppedImage_1_1,
    setCropDialogue,
  } = props;
  setButton_169_status(1);
  setButton_11_status(1);
  setCroppedImage_16_9(null);
  setCroppedImage_1_1(null);
  setCropDialogue(true);
};

export const handleDate = (props) => {
  const {date} = props;
  const formatDate = new Date(date);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const day = formatDate.getDate();
  const month = months[formatDate.getMonth()];
  const year = formatDate.getFullYear();
  return `${day} ${month}, ${year}`;
};

export const handleDeletePill = (props) => {
  const {
    item,
    keywordPillsData,
    setImageDetail,
    updateImageDetails,
    setImageDetailDrawerUpdated,
    setKeywordPillsData,
  } = props;
  let tempPills = [...keywordPillsData];
  let tempObj = [];
  tempPills.map((pill) => {
    tempObj.push(pill.title);
  });
  const index = tempObj?.indexOf(item.title);
  if (index > -1) {
    tempPills.splice(index, 1);
    //ImageDetail?.tags?.splice(index, 1); // 2nd parameter means remove one item only
  }
  let tempObj1 = [];
  tempPills.map((pill) => {
    tempObj1.push(pill.title);
  });
  setImageDetail((preState) => ({
    ...preState,
    tags: [...tempObj1],
  }));
  updateImageDetails({['tags']: tempObj1?.join(',')});
  setImageDetailDrawerUpdated(true);
  setKeywordPillsData(tempPills);
};

export const handleKeywordInput = (props) => {
  const {
    value,
    setSelectedKeyword,
    setUpdatedKeywordSuggestionCopy,
    keywordSuggestion,
    setKeywordDropdown,
    setKeywordSuggestion,
    KeywordSuggestionCopy,
  } = props;

  setSelectedKeyword({title: value});
  keywordInputHandle({
    value,
    setUpdatedKeywordSuggestionCopy,
    keywordSuggestion,
    setKeywordDropdown,
    setKeywordSuggestion,
    KeywordSuggestionCopy,
  });
};

export const updateWindowDimensions = (props) => {
  const {setWidth} = props;

  setWidth(window.innerWidth);
  //setHeight(window.innerHeight);
};

export const handleDeleteRecord = (props) => {
  const {
    imageId,
    makeRequest,
    setNotificationData,
    setDrawer,
    setImageId,
    setMainImg,
    imageinput,
    filterData,
    search,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    currentUserId,
    setDeleteRecord,
    setInput,
  } = props;
  handleDeleteImageAsset({
    imageId,
    makeRequest,
    setNotificationData,
    setDrawer,
    setImageId,
    setMainImg,
    imageinput,
    filterData,
    search,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setDashboardNotificationCountApi,
    setNotificationListApi,
    currentUserId,
  });
  setDeleteRecord(false);
  setInput('');
};

export const handleDeleteModal = (props) => {
  const {isDeleteRecord, setDeleteRecord} = props;
  setDeleteRecord(!isDeleteRecord);
};

export const handleCloseDeleteModal = (props) => {
  const {setDeleteRecord} = props;

  setDeleteRecord(false);
};

export const  handleImageClick = async (props) => {
  const {
    item,
    setImageThumbnailButtonClick,
    setImageId,
    setMainImg,
    setSelectedKeyword,
    setFileError,
    mainImageBackup,
    setKeywordDropdown,
    setIsLoading,
  } = props;
  setImageThumbnailButtonClick(true);
  setImageId(item?.imageId);
  setIsLoading(true)
  // const res  = await fetch(item.bannerImage, {
  //   headers: {
  //     'Content-Type': 'application/json',
  //     // 'authorization': _accessToken,
  //     // 'profile': _idToken
  //   },
  // })
  // const response_blob = await res.blob();
  // let reader = new FileReader();
  //     reader.readAsDataURL(response_blob);
  //     reader.onloadend =  function () {
  //       let base64_data = reader.result;
  //       console.log("🚀 ~ handleImageClick ~ base64_data:", base64_data)
  //       setMainImg(base64_data);
  //       setSelectedKeyword({title: ''});
  //       setKeywordDropdown(false);
  //       setFileError(false);
  //     }

    // .then(async function (response) {
    //   return await response.blob();
    // })
    // .then((data) => {
    //   var reader = new FileReader();
    //   reader.readAsDataURL(data);
    //   reader.onloadend = async function () {
    //     var base64_data = reader.result;
    //     setMainImg(base64_data);
    //     console.log("🚀 ~ .then ~ base64_data:")
    //     setSelectedKeyword({title: ''});
    //     setKeywordDropdown(false);
    //     setFileError(false);
    //   };
    // })



    //mywork
    const handleImageClick = async () => {
      try {
        // Fetch the image as a blob
        const res = await fetch(item.bannerImage, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const response_blob = await res.blob();

        // Convert blob to base64 and wait for the conversion to finish
        const base64_data = await handleImageConversion(response_blob);

        // Once conversion is done, proceed with setting state
        setMainImg(base64_data);
        setSelectedKeyword({title: ''});
        setKeywordDropdown(false);
        setFileError(false);
        
      } catch (error) {
        console.error('Error converting image:', error);
        setFileError(true); // Handle error case
      }
      finally{
        setIsLoading(false);
      }
    };

    handleImageClick();
};



const handleImageConversion = async (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result); // Resolve the Promise with the base64 result
    };
    reader.onerror = (error) => {
      reject(error); // Reject the Promise if there's an error
    };
  });
};

export const handleAddTags = (props) => {
  const {
    setKeywordDropdown,
    ImageDetail,
    setTags,
    updatedKeywordSuggestionCopy,
    selectedKeyword,
    setImageDetailDrawerUpdated,
    updateImageDetails,
    setImageDetail,
    setSelectedKeyword,
  } = props;
  setKeywordDropdown(false);
  let tempList = ImageDetail.tags ? [...ImageDetail?.tags] : [];
  setTags(
    tempList,
    updatedKeywordSuggestionCopy,
    selectedKeyword,
    setImageDetailDrawerUpdated,
    updateImageDetails,
    setImageDetail,
    ImageDetail,
  );
  setSelectedKeyword({title: ''});
};

export const handleFilters = (props) => {
  const {setFilterDropdown, filterDropdown} = props;
  setFilterDropdown(!filterDropdown);
};

export const handleAddNewImage = (props) => {
  const {setReplacedImage, setCroppedImage_16_9, setCroppedImage_1_1, setImageData, router} = props;
  setReplacedImage(null);
  setCroppedImage_16_9(null);
  setCroppedImage_1_1(null);

  setImageData({
    imageName: null,
    imageDescription: null,
    pillsData: null,
  });

  router.push({
    pathname: '/add-new-image',
    query: {actionName: ''},
  });
  // window.location.href = "/add-new-image";
};

export const closeDrawerModal = (props) => {
  const {
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
    setDrawer,
    setMainImg,
    setImageId,
  } = props;
  fetchImageList({
    filterData,
    search,
    makeRequest,
    setSearchFilterEmptyState,
    setErrorStateTemplate,
    isOnline,
    setImageList,
    setImageListAll,
    searchAndSort,
    setFilterDropdown,
    setCategory,
  });
  setDrawer(false);
  setImageId(0);
  setMainImg(undefined);
};

export const handleReplaceAllInstance = (props) => {
  const {setReplaceAllInstance, isReplaceAllInstance} = props;
  setReplaceAllInstance(!isReplaceAllInstance);
};

export const closeReplaceImageModal = (props) => {
  const {setShowReplaceModal} = props;
  setShowReplaceModal(false);
};

export const handleDeleteImage = (props) => {
  const {value, setInput} = props;
  setInput(value);
};

export const handleDeleteInput = (props) => {
  const {value, setInput} = props;

  setInput(value);
};

export const courseWizardCheck = (props) => {
  const {courseMode, setBlockNavigation} = props;

  return courseMode === 'FROM_COURSE_WIZARD' && setBlockNavigation(true);
};

export const learningPathWizardCheck = (props) => {
  const {learningPathMode, setBlockNavigation} = props;
  return learningPathMode === 'FROM_LEARNING_PATH' && setBlockNavigation(true);
};

export const searchLengthCheck = (props) => {
  const {search} = props;
  return search.length > 0 ? styles.active : '';
};

export const drawerOpenCheck = (props) => {
  const {isDrawer, newCourseWizardState, learningPathMode} = props;
  return isDrawer && !newCourseWizardState?.courseWizardOpen ? styles.is_drawer_open : '';
};
